Date.prototype.addDays = function (days) {
  let returnDate = new Date(this.valueOf());
  returnDate.setDate(returnDate.getDate() + days);
  return returnDate;
};
Date.prototype.removeDays = function (days) {
  let returnDate = new Date(this.valueOf());
  returnDate.setDate(returnDate.getDate() - days);
  return returnDate;
};

Date.prototype.addWeeks = function (weeks) {
  let returnDate = new Date(this.valueOf());
  returnDate = returnDate.addDays(7 * weeks);
  return returnDate;
};
Date.prototype.removeWeeks = function (weeks) {
  let returnDate = new Date(this.valueOf());
  returnDate = returnDate.removeDays(7 * weeks);
  return returnDate;
};
Date.prototype.addMonths = function (months) {
  let returnDate = new Date(this.valueOf());
  returnDate.setMonth(returnDate.getMonth() + months);
  return returnDate;
};
Date.prototype.removeMonths = function (months) {
  let returnDate = new Date(this.valueOf());
  returnDate.setMonth(returnDate.getMonth() - months);
  return returnDate;
};
Date.prototype.addYears = function (years) {
  let returnDate = new Date(this.valueOf());
  returnDate.setFullYear(returnDate.getFullYear() + years);
  return returnDate;
};
Date.prototype.removeYears = function (years) {
  let returnDate = new Date(this.valueOf());
  returnDate.setFullYear(returnDate.getFullYear() - years);
  return returnDate;
};
Date.prototype.addHours = function (iHours) {
  let returnDate = new Date(this.valueOf());
  returnDate.setHours(returnDate.getHours() + iHours);
  return returnDate;
};
Date.prototype.removeHours = function (iHours) {
  let returnDate = new Date(this.valueOf());
  returnDate.setHours(returnDate.getHours() - iHours);
  return returnDate;
};
Date.prototype.addMinutes = function (iMinutes) {
  let returnDate = new Date(this.valueOf());
  returnDate.setMinutes(returnDate.getMinutes() + iMinutes);
  return returnDate;
};
Date.prototype.removeMinutes = function (iMinutes) {
  let returnDate = new Date(this.valueOf());
  returnDate.setMinutes(returnDate.getMinutes() - iMinutes);
  return returnDate;
};
Date.prototype.addSeconds = function (iSeconds) {
  let returnDate = new Date(this.valueOf());
  returnDate.setSeconds(returnDate.getSeconds() + iSeconds);
  return returnDate;
};
Date.prototype.removeSeconds = function (iSeconds) {
  let returnDate = new Date(this.valueOf());
  returnDate.setSeconds(returnDate.getSeconds() - iSeconds);
  return returnDate;
};

export const DateFunctions = {
  isValidDate: function (date) {
    return date instanceof Date || (date != '' && !isNaN(Date.parse(date)));
  },
  getWeekNumber: function (date, includeYear = false) {
    // Ensure date is valid
    date = date ? new Date(date) : new Date();
    // Copy date so we don't modify original
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    //                          and make Sunday's day number 7
    date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
    // Get first day of year
    const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    const week = Math.ceil(((date - yearStart) / 86400000 + 1) / 7);
    // Return week number and year if requested
    return includeYear ? { year: date.getUTCFullYear(), week } : week;
  },

  getWeeksInYear: function (year) {
    const week = this.getWeekNumber(new Date(year, 11, 31));
    return week == 1 ? 52 : week;
  },

  buildWeek: function (iBaseDate, iNumberOfWeeks = 1) {
    this.weeks = [];

    let buildDate = iBaseDate ? new Date(iBaseDate) : new Date();
    buildDate.setHours(0, 0, 0, 0);
    // FIND MONDAY

    // console.log('BUILDWEEK iBaseDate:', iBaseDate);
    // console.log('BUILDWEEK buildDate:', buildDate);

    let day = buildDate.getDay(),
      diff = buildDate.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday

    buildDate = new Date(buildDate.setDate(diff));

    // let endDate = iEndDate ? Date(iEndDate) : new Date();
    // endDate.setDate(endDate.getDate() + 42);
    // endDate.setHours(0, 0, 0, 0);
    // FIND SUNDAY

    let tmpWeeks = [];

    for (let weekNumber = 0; weekNumber < iNumberOfWeeks; weekNumber++) {
      let weekDays = [];

      for (let dayNumber = 0; dayNumber < 7; dayNumber++) {
        weekDays.push({
          Day: new Date(buildDate),
          SessionList: [],
        });

        buildDate.setDate(buildDate.getDate() + 1);
      }

      tmpWeeks.push(weekDays);
    }

    return tmpWeeks;
  },

  getMonday: function (date) {
    date = new Date(date);
    let day = date.getDay();
    let diff = date.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday

    return new Date(date.setDate(diff));
  },
  getSunday: function (date) {
    return this.getMonday(date).addDays(6);
  },

  isToday: function (date) {
    if (date) {
      date = new Date(date);
      const today = new Date();
      return date.getDate() == today.getDate() && date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear();
    }
    return false;
  },
  isSameDay: function (date_1, date_2) {
    if (date_1 && date_2) {
      date_1 = new Date(date_1).setHours(0, 0, 0, 0);
      date_2 = new Date(date_2).setHours(0, 0, 0, 0);
      return date_1 == date_2;
    }
    return false;
  },
  isPast: function (date) {
    if (date) {
      date = new Date(date).setHours(0, 0, 0, 0);
      const today = new Date().setHours(0, 0, 0, 0);
      return date < today;
    }
    return false;
  },
  isFuture: function (date) {
    if (date) {
      date = new Date(date).setHours(0, 0, 0, 0);
      const today = new Date().setHours(0, 0, 0, 0);
      return date > today;
    }
    return false;
  },
  timeBetween: function (iStartDate, iEndDate) {
    let diff = Math.abs(new Date(iEndDate) - new Date(iStartDate));
    return Math.floor(diff / 1000 / 60);
  },
  secondsBetween: function (iStartDate, iEndDate) {
    let diff = Math.abs(new Date(iEndDate) - new Date(iStartDate));
    return Math.floor(diff / 1000);
  },
  getTimeFromDate: function (date = new Date(), includeSeconds = false, includeMilliseconds = false) {
    date = new Date(date ?? new Date());
    return (
      (date.getHours() < 10 ? '0' : '') + date.getHours() +
      ':' +
      ((date.getMinutes() < 10 ? '0' : '') + date.getMinutes()) +
      (includeSeconds ? ':' + (date.getSeconds() < 10 ? '0' : '') + date.getSeconds() : '') +
      (includeMilliseconds ? '.' + date.getMilliseconds() : '')
    );
  },

  DD: function (date = new Date()) {
    return this.isValidDate(date) ? new Date(date).toLocaleDateString(undefined, { day: '2-digit' }) : '';
  },

  Do: function (date = new Date()) {
    const day = this.isValidDate(date) ? new Date(date).getDate() : '';
    return `${day}${day > 0 ? ['th', 'st', 'nd', 'rd'][(day > 3 && day < 21) || day % 10 > 3 ? 0 : day % 10] : ''}`;
  },

  DDD: function (date = new Date()) {
    return this.isValidDate(date) ? new Date(date).toLocaleDateString(undefined, { weekday: 'short' }) : '';
  },

  DDDD: function (date = new Date()) {
    return this.isValidDate(date) ? new Date(date).toLocaleDateString(undefined, { weekday: 'long' }) : '';
  },

  MM: function (date = new Date()) {
    return this.isValidDate(date) ? new Date(date).toLocaleDateString(undefined, { month: '2-digit' }) : '';
  },

  MMM: function (date = new Date()) {
    return this.isValidDate(date) ? new Date(date).toLocaleDateString(undefined, { month: 'short' }) : '';
  },

  MMMM: function (date = new Date()) {
    return this.isValidDate(date) ? new Date(date).toLocaleDateString(undefined, { month: 'long' }) : '';
  },

  YYYY: function (date = new Date()) {
    return this.isValidDate(date) ? new Date(date).toLocaleDateString(undefined, { year: 'numeric' }) : '';
  },

  getDDMMYYYY: function (date, seperator = '/') {
    return this.isValidDate(date) ? this.DD(date) + seperator + this.MM(date) + seperator + this.YYYY(date) : '';
  },
  getDDMMMYYYY: function (date) {
    return this.isValidDate(date) ? this.DD(date) + ' ' + this.MMM(date) + ' ' + this.YYYY(date) : '';
  },
  getYYYYMMDD: function (date, seperator = '/') {
    return this.isValidDate(date) ? this.YYYY(date) + seperator + this.MM(date) + seperator + this.DD(date) : '';
  },
  getMMDDYYYY: function (date, seperator = '/') {
    return this.isValidDate(date) ? this.MM(date) + seperator + this.DD(date) + seperator + this.YYYY(date) : '';
  },

  addDays: function (date, iDays) {
    return new Date(date).addDays(iDays);
  },
  removeDays: function (date, iDays) {
    return new Date(date).removeDays(iDays);
  },

  addWeeks: function (date, iWeeks) {
    return this.addDays(date, 7 * iWeeks);
  },
  removeWeeks: function (date, iWeeks) {
    return this.removeDays(date, 7 * iWeeks);
  },

  addHours: function (date, iHours) {
    let returnDate = new Date(date);
    returnDate.setHours(returnDate.getHours() + iHours);
    return returnDate;
  },
  removeHours: function (date, iHours) {
    let returnDate = new Date(date);
    returnDate.setHours(returnDate.getHours() - iHours);
    return returnDate;
  },

  addMinutes: function (date, iMinutes) {
    let returnDate = new Date(date);
    returnDate.setMinutes(returnDate.getMinutes() + iMinutes);
    return returnDate;
  },
  removeMinutes: function (date, iMinutes) {
    let returnDate = new Date(date);
    returnDate.setMinutes(returnDate.getMinutes() - iMinutes);
    return returnDate;
  },

  addSeconds: function (date, iSeconds) {
    let returnDate = new Date(date);
    returnDate.setSeconds(returnDate.getSeconds() + iSeconds);
    return returnDate;
  },
  removeSeconds: function (date, iSeconds) {
    let returnDate = new Date(date);
    returnDate.setSeconds(returnDate.getSeconds() - iSeconds);
    return returnDate;
  },

  apiDate: function (date = new Date(), iSep = '-') {
    date = new Date(date);
    return this.getYYYYMMDD(date, iSep);
  },

  apiDateTime: function (date = new Date(), iSep = '-') {
    date = new Date(date);
    return this.getYYYYMMDD(date, iSep) + 'T' + this.getTimeFromDate(date);
  },
  getApiDateTime: function (date, iTime) {
    return this.apiDate(date) + 'T' + iTime + ':00';
  },
  getDateTimeString: function (iDateTime, includeSeconds = false, midSeperator = ' ') {
    return this.getDDMMYYYY(iDateTime) + midSeperator + this.getTimeFromDate(iDateTime, includeSeconds);
  },
  humanReadableDate: function (date) {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return new Date(date).toLocaleDateString('en-GB', options);
  },
  humanReadableDateAndTime: function (
    date,
    options = {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      // second: '2-digit',
    }
  ) {
    return new Date(date).toLocaleDateString('en-GB', options);
  },
  elapsedTime: function (iActionDate) {
    return this.secondsToDhms(this.timeBetween(new Date(), iActionDate) * 60);
  },
  secondsToDhms: function (seconds) {
    seconds = Number(seconds);
    let d = Math.floor(seconds / (3600 * 24));
    let h = Math.floor((seconds % (3600 * 24)) / 3600);
    let m = Math.floor((seconds % 3600) / 60);
    let s = Math.floor(seconds % 60);

    let dDisplay = d > 0 ? d + (d == 1 ? ' day, ' : ' days, ') : '';
    let hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : '';
    let mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : '';
    let sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';
    return dDisplay + hDisplay + mDisplay + sDisplay;
  },

  secondsToHHMMSS: function (seconds) {
    return seconds ? new Date(seconds * 1000).toISOString().substring(11, 19) : null;
  },

  minutesToHHMM: function (minutes) {
    return minutes ? new Date(minutes * 60 * 1000).toISOString().substring(11, 16) : '00:00';
  },
  minutesToHHMMSS: function (minutes) {
    return minutes ? new Date(minutes * 60 * 1000).toISOString().substring(11, 19) : '00:00:00';
  },

  treatAsUTC: function (date) {
    let returnDate = new Date(date);
    returnDate.setMinutes(returnDate.getMinutes() - returnDate.getTimezoneOffset());
    return returnDate;
  },

  daysBetween: function (startDate, endDate) {
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    return Math.round((this.treatAsUTC(endDate) - this.treatAsUTC(startDate)) / millisecondsPerDay);
  },
  weeksBetween: function (startDate, endDate) {
    const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
    return Math.round((this.treatAsUTC(endDate) - this.treatAsUTC(startDate)) / millisecondsPerWeek);
  },
  getDatesBetween: function (startDate, endDate) {
    var dateArray = new Array();
    var currentDate = startDate;
    while (currentDate <= endDate) {
      dateArray.push(new Date(currentDate).toDateString());
      currentDate = currentDate.addDays(1);
    }
    return dateArray;
  },
  convertTo24Hour(time) {
    var hours = parseInt(time.substr(0, 2));
    if (time.indexOf('AM') != -1 && hours == 12) {
      time = time.replace('12', '0');
    }
    if (time.indexOf('PM') != -1 && hours < 12) {
      time = time.replace(hours, hours + 12);
    }
    return time.replace(/(AM|PM)/, '');
  },

  timeConvert(n) {
    let returnTime;
    let num = n;
    let textHour;
    let textMinute;
    let hours = num / 60;
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);

    textHour = rhours == 1 ? 'hour' : 'hours';
    textMinute = rminutes == 1 ? 'minute' : 'minutes';

    if (num == 1) {
      returnTime = `${rminutes} minute`;
    } else if (num == 0 || (num > 1 && num < 60)) {
      returnTime = `${num} minutes`;
    } else if (num == 60) {
      returnTime = `${hours} hour`;
    } else if (num > 60) {
      returnTime = `${rhours} ${textHour} & ${rminutes} ${textMinute}`;
    }
    return returnTime;
  },
};
